import Counseling1 from '../imgs/Office.webp';
import Counseling2 from '../imgs/Zoey.webp';
import Counseling3 from '../imgs/Counseling3.jpg';

const features = [
  {
    title: 'What to Expect?',
    image: Counseling1,
    image_alt: "Encouraging mental health sign.",
    description: 'During your session, you can expect a calming, supportive, and collaborative environment where we explore your concerns confidentially and work together towards your goals.',
  },
  {
    title: 'Therapy Dog in Training',
    image: Counseling2,
    image_alt: "Zoey",
    description: 'Meet Zoey, a hypoallergenic mini bernedoodle. You have the option to include Zoey in your counseling sessions to provide comfort and emotional support.',
  },
  {
    title: 'Medical Insurance',
    image: Counseling3,
    image_alt: "Mug and journal laying on a desk.",
    description:
    'Insurance is accepted for counseling sessions, but you also have the option to pay out-of-pocket if preferred.',
  },
];

const Counseling = () => {
  return (
    <section id="counseling" className="bg-[#dbf1f1] my-8 py-8" style={{"scroll-margin-top": "100px"}}>
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-3xl md:text-center">
          <p className="custom-h2">
            I&apos;m Here To Help
          </p>
          <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Counseling Services
          </h1>
          <p className="mt-6 text-gray-500">
            In my solo practice, I provide personalized counseling in a calm and
            confidential setting. Together, we&apos;ll address your unique
            concerns and goals using techniques tailored to your needs. Whether
            you&apos;re facing stress, anxiety, relationship issues, or seeking
            personal growth, my goal is to offer dedicated support to help you
            make meaningful progress.
          </p>
        </div>
        <div className="grid grid-cols-1 gap-x-12 gap-y-10 pt-8 md:grid-cols-2 lg:grid-cols-3">
          {features.map(({ title, image, image_alt, description }, index) => (
            <div
              key={index}
              className={`space-y-6 pb-2 ${index === 0 ? 'col-span-1 md:col-span-2 lg:col-span-1' : ''}`}
            >
              <div className="flex w-full items-center justify-center">
                <img
                  className="h-64 rounded-xl shadow-lg object-cover"
                  loading="lazy"
                  alt={image_alt}
                  src={image}
                />
              </div>
              <p className="flex w-full items-center justify-center font-medium text-gray-900">
                {title}
              </p>
              <p className="text-center text-sm text-gray-500">{description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Counseling;
